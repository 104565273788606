import React from 'react';
import 'animate.css';
import ProgressBar from './ProgressBar';
import BackgroundImg from './images/blue-background.jpg';

const AnimalMessage = ({ animal }) => {
  if (animal === 'whale') {
    return "Wow, that's a lot of XEC! You are the big fish in the pond. Don't spend it all in one place!";
  } else if (animal === 'orca') {
    return 'Wow! You’re crushing it! Have you considered applying to the GNC?';
  } else if (animal === 'shark') {
    return 'Wow! You’re crushing it! The eCash Army needs more leaders like you!';
  } else if (animal === 'swordfish') {
    return 'Wow that’s a decent amount of eCash! The eCash Army needs more people like you!';
  } else if (animal === 'barracuda') {
    return 'Wow that’s a decent amount of eCash! Your support makes this project possible!';
  } else if (animal === 'octopus') {
    return 'Wow that’s a decent amount of eCash! Have you tried staking?';
  } else if (animal === 'piranha') {
    return 'Way to go - you’re on the board! Keep doing what you’re doing, and don’t forget you can always get some more.';
  } else if (animal === 'crab') {
    return 'Way to go - you’re on the board! Keep doing what you’re doing, and don’t forget you can always get some more.';
  } else if (animal === 'shrimp') {
    return 'Way to go! Your journey is only just getting started.';
  } else return 'Wow that is a lot of XEC! Keep Hodling!';
};

function capLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const RevealScreen = ({ animal, holdingamount, setAnimal }) => {
  return (
    <div className="animate__animated animate__zoomIn">
      <div
        className="animal-ctn"
        style={{ backgroundImage: 'url(' + BackgroundImg + ')' }}
      >
        <div className="animal-background-ctn">
          <img
            src={`/images/${animal}.png`}
            className="animal-img animate__animated animate__jackInTheBox"
            style={{ animationDelay: '.5s' }}
            alt={animal}
          />
        </div>
        <div
          className="text-ctn animate__animated animate__jackInTheBox"
          style={{ animationDelay: '.7s' }}
        >
          <h2>
            You're a{animal === 'orca' || animal === 'octopus' ? 'n' : null}{' '}
            <span>{capLetter(animal)}!</span>
          </h2>
          <ProgressBar animal={animal} holdingamount={holdingamount} />
          <div className="description">
            <AnimalMessage animal={animal} />
          </div>
          <div className="home-btn" onClick={() => setAnimal('')}>
            Try another amount
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevealScreen;
