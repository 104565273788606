import React, { useState, useEffect } from 'react';

const calcPercent = (nextlevel, currentlevel) => {
  return ((currentlevel / nextlevel) * 100).toFixed().toString();
};

const ProgressBar = ({ animal, holdingamount }) => {
  const [progress, setProgress] = useState({
    percentage: 0,
    currLevel: 1,
    nextLevel: 2,
    nextAnimal: 'crab',
  });

  const NextPercent = (animaltype, amount) => {
    let y = parseFloat(amount.replace(/,/g, ''));
    if (animaltype === 'whale') {
      setProgress({
        percentage: 100,
        currLevel: 9,
        nextAnimal: 'whale',
      });
    } else if (animaltype === 'orca') {
      setProgress({
        percentage: calcPercent(20000000000, y),
        currLevel: 8,
        nextAnimal: 'whale',
      });
    } else if (animaltype === 'shark') {
      setProgress({
        percentage: calcPercent(10000000000, y),
        currLevel: 7,
        nextAnimal: 'orca',
      });
    } else if (animaltype === 'swordfish') {
      setProgress({
        percentage: calcPercent(5000000000, y),
        currLevel: 6,
        nextAnimal: 'shark',
      });
    } else if (animaltype === 'barracuda') {
      setProgress({
        percentage: calcPercent(2000000000, y),
        currLevel: 5,
        nextAnimal: 'swordfish',
      });
    } else if (animaltype === 'octopus') {
      setProgress({
        percentage: calcPercent(500000000, y),
        currLevel: 4,
        nextAnimal: 'barracuda',
      });
    } else if (animaltype === 'piranha') {
      setProgress({
        percentage: calcPercent(100000000, y),
        currLevel: 3,
        nextAnimal: 'octopus',
      });
    } else if (animaltype === 'crab') {
      setProgress({
        percentage: calcPercent(10000000, y),
        currLevel: 2,
        nextAnimal: 'piranha',
      });
    } else if (animaltype === 'shrimp') {
      setProgress({
        percentage: calcPercent(1000000, y),
        currLevel: 1,
        nextAnimal: 'crab',
      });
    } else
      setProgress({
        precentage: 50,
        currLevel: 1,
        nextAnimal: 'crab',
      });
  };

  useEffect(() => {
    NextPercent(animal, holdingamount);
  }, [animal, holdingamount]);

  return (
    <div className="bar-section-ctn">
      <div className="amount">{holdingamount} XEC</div>
      <div className="bar-ctn">
        <div className="level-ctn">
          <img src={`/images/${animal}-icon.png`} alt="animal" />
          <div className="level">Lvl {progress.currLevel}</div>
        </div>
        <div className="outer-bar">
          <div
            className="inner-bar"
            style={{
              width: progress.percentage + '%',
            }}
          ></div>
        </div>
        {progress.currLevel !== 9 && (
          <div className="level-ctn">
            <div className="level">Lvl {progress.currLevel + 1}</div>
            <img src={`/images/${progress.nextAnimal}-icon.png`} alt="animal" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
