import React, { useState, useEffect } from 'react';
import 'animate.css';
import SiteLinks from './SiteLinks/SiteLinks.js';
import Logo from './images/ecash-logo-horizontal.png';
import RevealScreen from './RevealScreen';
import Video from './images/video-background.mp4';
import BackgroundImg from './images/video-img.jpg';
import Loading from './images/loading.gif';

const App = () => {
  const [holdingamount, setHoldingAmount] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [animal, setAnimal] = useState('');

  const imageList = [
    'whale',
    'orca',
    'shark',
    'swordfish',
    'barracuda',
    'octopus',
    'piranha',
    'crab',
    'shrimp',
  ];

  useEffect(() => {
    imageList.forEach((image) => {
      new Image().src = `/images/${image}.png`;
    });
  }, []);

  useEffect(() => {
    if (!animal) {
      const listener = (event) => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
          event.preventDefault();
          EnterXEC(holdingamount);
        }
      };
      document.addEventListener('keydown', listener);
      return () => {
        document.removeEventListener('keydown', listener);
      };
    } else {
      const listener = (event) => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
          event.preventDefault();
          setAnimal('');
        }
      };
      document.addEventListener('keydown', listener);
      return () => {
        document.removeEventListener('keydown', listener);
      };
    }
  }, [holdingamount, animal]);

  const FormatInput = (e) => {
    if (e >= 37 && e <= 40) {
      e.preventDefault();
    }
    if (e.match(/[^$,.\d]/)) {
      setError(true);
    } else {
      setError(false);
      var num = e.replace(/,/gi, '');
      var num2 = num.split(/(?=(?:\d{3})+$)/).join(',');
      setHoldingAmount(num2);
    }
  };

  const EnterXEC = (x) => {
    let y = parseFloat(x.replace(/,/g, ''));
    if (y >= 20000000000) {
      runSwitch('whale');
    } else if (y >= 10000000000 && y < 20000000000) {
      runSwitch('orca');
    } else if (y >= 5000000000 && y < 10000000000) {
      runSwitch('shark');
    } else if (y >= 2000000000 && y < 5000000000) {
      runSwitch('swordfish');
    } else if (y >= 500000000 && y < 2000000000) {
      runSwitch('barracuda');
    } else if (y >= 100000000 && y < 500000000) {
      runSwitch('octopus');
    } else if (y >= 10000000 && y < 100000000) {
      runSwitch('piranha');
    } else if (y >= 1000000 && y < 10000000) {
      runSwitch('crab');
    } else if (y >= 1 && y < 1000000) {
      runSwitch('shrimp');
    }
  };

  const runSwitch = (value) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setAnimal(value);
    }, 2000);
  };

  return (
    <div>
      {!animal && (
        <>
          <div
            className="landingbackground-image"
            style={{ backgroundImage: 'url(' + BackgroundImg + ')' }}
          />
          <div className="overlay" />
          <div className="video-ctn">
            <video
              playsInline
              autoPlay
              muted
              loop
              poster={BackgroundImg}
              id="ecash_background"
            >
              <source src={Video} type="video/mp4" />
            </video>
          </div>
        </>
      )}

      {/***************** HERO SECTION *****************/}

      {loading && (
        <div className="block main-ctn">
          <div className="container">
            <div className="input-ctn">
              <img src={Loading} alt="loading" />
            </div>
          </div>
        </div>
      )}

      {!loading && !animal && (
        <div className="block main-ctn">
          <div className="container">
            <div className="input-ctn animate__animated animate__fadeInUp">
              <div className="header animate__animated animate__fadeInUp">
                <a href="https://e.cash" target="_blank" rel="noreferrer">
                  <img src={Logo} alt="ecash logo" />
                  <span>|</span>
                  <h2 className="glitch" data-text="Holdings">
                    Holdings
                  </h2>
                </a>
              </div>
              <p
                className="animate__animated animate__fadeInUp"
                style={{ animationDelay: '.4s' }}
              >
                Enter how many XEC you have to see what kind of holder you are!
              </p>
              <div
                className="input-field-ctn"
                style={{ animationDelay: '.6s' }}
              >
                <input
                  type="text"
                  id="holdings"
                  name="holdings"
                  placeholder="XEC Holdings"
                  autoFocus
                  onChange={(e) => FormatInput(e.target.value)}
                  value={holdingamount}
                  data-type="number"
                  autoComplete="off"
                  maxLength="17"
                />
                <div className="suffix">XEC</div>
              </div>
              {holdingamount ? (
                <div
                  className="submit-btn btn-active"
                  onClick={() => EnterXEC(holdingamount)}
                >
                  Enter
                </div>
              ) : (
                <div className="submit-btn" style={{ animationDelay: '.8s' }}>
                  Enter
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {animal && (
        <RevealScreen
          animal={animal}
          holdingamount={holdingamount}
          setAnimal={setAnimal}
        />
      )}

      {/************************ SITE LINKS SECTION *********************/}

      <SiteLinks />
    </div>
  );
};

export default App;
